import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, Grid, Typography } from '@material-ui/core';
import { orderBy, size, slice } from 'lodash';
import request from 'superagent';
import ReactPaginate from 'react-paginate';

import { muiTheme, defaultThemeOption } from './utils/tools';
import Loader from './utils/Loader';
import NewsCard from './components/NewsCard';
import config from './config';

const NUMBER_OF_ITEMS_PER_PAGE =
  parseInt(window.itemPerPageNews, 10) > 0
    ? parseInt(window.itemPerPageNews, 10)
    : 10;

class News extends Component {
  constructor(props) {
    super(props);
    this.state = { currentPage: 0 };
  }

  componentDidMount() {
    const { cityId, aggloId, cityINSEE, aggloSIREN, excludeActors } = this.props;
    if (cityId || aggloId) {
      this.fetchNews(cityId, aggloId, excludeActors);
    } else if (cityINSEE || aggloSIREN) {
      this.fetchNewsByCode(cityINSEE, aggloSIREN, excludeActors);
    }
  }

  fetchNews(cityId, aggloId, excludeActors) {
    request
      .get(config.apiRoot + config.apiNews)
      .query({ 'city-id': cityId })
      .query({ 'agglo-id': aggloId })
      .query({ 'exclude-actors': excludeActors ? '1' : '0' })
      .query({ source: 'widget' })
      .then((res) => res.body)
      .then((news) => {
        this.setState({
          news: orderBy(news, ['updated_at'], ['desc']),
          fetched: true,
        });
      })
      .catch((err) => {
        this.setState({ error: true });
        console.log('error during call : ' + err);
      });
  }

  fetchNewsByCode(cityINSEE, aggloSIREN, excludeActors) {
    request
      .get(config.apiRoot + config.apiNews)
      .query({ 'city-insee': cityINSEE })
      .query({ 'agglo-siren': aggloSIREN })
      .query({ 'exclude-actors': excludeActors ? '1' : '0' })
      .query({ source: 'widget' })
      .then((res) => res.body)
      .then((news) => {
        this.setState({
          news: orderBy(news, ['updated_at'], ['desc']),
          fetched: true,
        });
      })
      .catch((err) => {
        this.setState({ error: true });
        console.log('error during call : ' + err);
      });
  }

  handlePageClick = (data) => {
    console.log(data);
    this.setState({ currentPage: data.selected });
    document.activeElement.blur();

    const elmnt = document.getElementById('intramuros_news');
    if (elmnt) {
      elmnt.scrollIntoView(true);
    }
  };

  render() {
    const { error, fetched, currentPage } = this.state;
    let { news } = this.state;

    if (!news) {
      if (error || fetched) {
        return null;
      }
      return <Loader />;
    }

    let divStyle = { overflowY: 'auto', overflowX: 'hidden' };
    if (window.maxHeight > 0) {
      divStyle = { ...divStyle, maxHeight: parseInt(window.maxHeight, 10) };
    }
    if (window.backgroundColor) {
      divStyle = { ...divStyle, backgroundColor: window.backgroundColor };
    }

    const numberOfNews = size(news);
    const numberOfPages = Math.ceil(numberOfNews / NUMBER_OF_ITEMS_PER_PAGE);
    console.log('number of pages = ' + numberOfPages);

    //  Calculate list of current page
    if (numberOfPages > 1) {
      news = slice(
        news,
        currentPage * NUMBER_OF_ITEMS_PER_PAGE,
        currentPage * NUMBER_OF_ITEMS_PER_PAGE + NUMBER_OF_ITEMS_PER_PAGE,
      );
    }

    const customTypography = window.forceFont
      ? { fontFamily: ['Lato', 'sans-serif'].join(',') }
      : { fontFamily: 'inherit' };

    const themeOptions = {
      ...defaultThemeOption,
      typography: customTypography,
    };

    const theme = muiTheme(themeOptions);

    if (numberOfNews < 1) {
      return (
        <div style={divStyle}>
          <MuiThemeProvider theme={theme}>
            <Typography
              align="center"
              style={{
                fontWeight: 'bold',
                marginTop: 20,
                marginBottom: 20,
                color: '#333',
              }}
            >
              {'Aucune publication'}
            </Typography>
          </MuiThemeProvider>
        </div>
      );
    }

    return (
      <div style={divStyle}>
        <MuiThemeProvider theme={theme}>
          {news.map((theNews) => (
            <NewsCard news={theNews} key={theNews.id} />
          ))}
          {numberOfPages > 1 ? (
            <Grid container justifyContent="center" style={{ marginTop: 30 }}>
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={numberOfPages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1} // saut de page: quand on clique sur les ...
                onPageChange={this.handlePageClick}
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                activeClassName="active"
                forcePage={currentPage}
              />
            </Grid>
          ) : null}
        </MuiThemeProvider>
      </div>
    );
  }
}

News.propTypes = {
  cityId: PropTypes.number,
  aggloId: PropTypes.number,
  cityINSEE: PropTypes.string,
  aggloSIREN: PropTypes.string,
};

export default News;
